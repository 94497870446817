@font-face {
  font-family: "Gmarket Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Gmarket Sans Bold"), local("GmarketSans-Bold"),
    url("https://script.ebay.co.kr/fonts/GmarketSansBold.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("https://script.ebay.co.kr/fonts/GmarketSansBold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Gmarket Sans";
  font-style: normal;
  font-weight: 500;
  src: local("Gmarket Sans Medium"), local("GmarketSans-Medium"),
    url("https://script.ebay.co.kr/fonts/GmarketSansMedium.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("https://script.ebay.co.kr/fonts/GmarketSansMedium.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Gmarket Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Gmarket Sans Light"), local("GmarketSans-Light"),
    url("https://script.ebay.co.kr/fonts/GmarketSansLight.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("https://script.ebay.co.kr/fonts/GmarketSansLight.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700;900&display=swap");

body {
  /* background-color: #0384c7; */
  font-family: "Gmarket Sans", sans-serif;
}

polygon {
  stroke: transparent;
}

.form-err-msg {
  font-weight: normal !important;
}

.text-xxs {
  font-size: 0.5rem;
  line-height: 1rem;
}

.bg-instagram {
  --tw-bg-opacity: 1;
  background-color: rgba(210, 95, 171, var(--tw-bg-opacity));
}

.bg-mplanet {
  --tw-bg-opacity: 1;
  /* background-color: rgba(241, 163, 155, var(--tw-bg-opacity)); */
  background-color: rgba(17, 53, 115, var(--tw-bg-opacity));
}

.active\:bg-mplanet:active {
  --tw-bg-opacity: 1;
  /* background-color: rgba(183, 122, 117, var(--tw-bg-opacity)); */
  background-color: rgba(12, 37, 81, var(--tw-bg-opacity));
}

.text-instagram {
  --tw-bg-opacity: 1;
  color: rgba(168, 85, 247, var(--tw-bg-opacity));
}

.mplanet-btn-bg {
  /* background-color: rgba(246, 186, 180, 1); */
  background-color: white;
  color: rgba(135, 116, 179, 1);
}

.mplanet-btn-bg:hover {
  /* background-color: rgba(246, 186, 180, 1); */
  background-color: rgba(135, 116, 179, 1);
  color: white;
}

.mplant-btn-footer-bg {
  background-color: rgba(246, 186, 180, 1);
}

.apply-btn {
  bottom: -1rem;
  right: -6rem;
  z-index: 4;
}

.floating-btn-1 {
  bottom: 10rem;
  right: 1.5rem;
  z-index: 4;
}
.floating-btn-2 {
  bottom: 10rem;
  right: 1rem;
  z-index: 4;
}

.m-hidden {
  @media (min-width: 624px) {
    display: none;
  }
}

.floating-btn-3 {
  bottom: 10.5rem;
  right: 1rem;
  z-index: 4;
}

.apply-img {
  width: 340px;
  height: 220px;
}

.floating-btn-img {
  width: 100px;
}

.policy-font {
  font-family: "Noto Sans KR", sans-serif;
}

.w-1\/4 {
  width: 25%;
}

.larger_td {
  width: 300px;
}

.border-b-1 {
  border-bottom-width: 1px;
}

.creator-text {
  font-size: 1.875rem;
  line-height: 2.75rem;
}
.br-hidden {
  display: none;
}
.bg-lg-hidden {
  display: block;
}
@media (max-width: 1320px) {
  .xl\:creator-text {
    font-size: 1.45rem;
    line-height: 2.5rem;
  }
}
@media (max-width: 1040px) {
  .lg\:creator-text {
    font-size: 1.2rem;
    line-height: 2.25rem;
  }
}
@media (max-width: 620px) {
  .lg\:creator-text {
    font-size: 0.9rem;
    line-height: 2rem;
  }
  .br-hidden {
    display: block;
  }
  .bg-lg-hidden {
    display: none;
  }
}

@media (min-width: 1024px) {
  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pt-48 {
    padding-top: 12rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .md\:pt-12 {
    padding-top: 3rem;
  }
}

@media (max-width: 1500px) {
  .mxxl\:table-auto {
    table-layout: auto;
  }
}

@media (max-width: 768px) {
  /* .mxmd\:ml-4 {
    margin-left: 1rem;
  } */
  .mxmd\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1024px) {
  .hidden-mobile {
    display: none;
  }
}

@media (max-width: 602px) {
  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:floating-btn-1 {
    bottom: 9rem;
    right: 2rem;
  }

  .sm\:apply-btn {
    bottom: -0.5rem;
    right: -5rem;
    z-index: 4;
  }

  .sm\:apply-img {
    width: 280px;
    height: 180px;
  }

  .sm\:floating-btn-img {
    width: 80px;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (max-width: 502px) {
  .xs\:w-90p {
    width: 90%;
  }

  .xs\:apply-btn {
    bottom: 1rem;
    right: -3.5rem;
    z-index: 4;
  }

  .xs\:apply-img {
    width: 215px;
    height: 135px;
  }

  .xs\:floating-btn-1 {
    bottom: 7.5rem;
    right: 2rem;
  }

  .xs\:floating-btn-img {
    width: 60px;
  }

  .xs\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xs\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xs\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (max-width: 405px) {
  .mini\:checkbox {
    width: 1rem;
    height: 1rem;
  }
  .mini\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .mini\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .mini\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .mini\:text-3xl {
    font-size: 1.8rem;
    line-height: 2.25rem;
  }
  .mini\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .mini\:text-xxs {
    font-size: 0.5rem;
    line-height: 1rem;
  }
  .mini\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .mini\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .mini\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
