@font-face {
  font-family: "majors-font";
  src: url("../fonts/majors-font.eot?6m96lw");
  src: url("../fonts/majors-font.eot?6m96lw#iefix") format("embedded-opentype"),
    url("../fonts/majors-font.ttf?6m96lw") format("truetype"),
    url("../fonts/majors-font.woff?6m96lw") format("woff"),
    url("../fonts/majors-font.svg?6m96lw#majors-font") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "majors-font" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* line-height: 1; */

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mplanet-ori:before {
  content: "\e900";
  color: #f6bab4;
}
.icon-mplanet-purple:before {
  content: "\e901";
  color: #8774b3;
}
.icon-tiktok-colored .path1:before {
  content: "\e928";
  color: rgb(37, 244, 238);
}
.icon-tiktok-colored .path2:before {
  content: "\e929";
  margin-left: -0.884765625em;
  color: rgb(37, 244, 238);
}
.icon-tiktok-colored .path3:before {
  content: "\e92a";
  margin-left: -0.884765625em;
  color: rgb(37, 244, 238);
}
.icon-tiktok-colored .path4:before {
  content: "\e92b";
  margin-left: -0.884765625em;
  color: rgb(254, 44, 85);
}
.icon-tiktok-colored .path5:before {
  content: "\e92c";
  margin-left: -0.884765625em;
  color: rgb(254, 44, 85);
}
.icon-tiktok-colored .path6:before {
  content: "\e92d";
  margin-left: -0.884765625em;
  color: rgb(254, 44, 85);
}
.icon-tiktok-colored .path7:before {
  content: "\e92e";
  margin-left: -0.884765625em;
  color: rgb(0, 0, 0);
}
.icon-naver-circle:before {
  content: "\e910";
  color: #06be34;
}
.icon-naver:before {
  content: "\e911";
  color: #06be34;
}
.icon-naver-white:before {
  content: "\e911";
  color: #ffffff;
}
.icon-facebook-colored:before {
  content: "\e912";
  color: #1877f2;
}
.icon-instagram-colored:before {
  content: "\e913";
  color: #e4405f;
}
.icon-tiktok:before {
  content: "\e914";
}
.icon-youtube-colored:before {
  content: "\e915";
  color: #f00;
}
.icon-tiktok-subset:before {
  content: "\e927";
}
.icon-award:before {
  content: "\e926";
}
.icon-check:before {
  content: "\f00c";
}
.icon-video-camera:before {
  content: "\f03d";
}
.icon-facebook-square:before {
  content: "\f082";
}
.icon-trophy:before {
  content: "\f091";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-facebook-f:before {
  content: "\f09a";
}
.icon-hand-o-down:before {
  content: "\f0a7";
}
.icon-group:before {
  content: "\f0c0";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-copy:before {
  content: "\f0c5";
}
.icon-files-o:before {
  content: "\f0c5";
}
.icon-circle:before {
  content: "\f111";
}
.icon-rocket:before {
  content: "\f135";
}
.icon-youtube:before {
  content: "\f16a";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-graduation-cap:before {
  content: "\f19d";
}
.icon-mortar-board:before {
  content: "\f19d";
}
.icon-newspaper-o:before {
  content: "\f1ea";
}
.icon-facebook-official:before {
  content: "\f230";
}
.icon-handshake-o:before {
  content: "\f2b5";
}
.icon-fire:before {
  content: "\e9a9";
}
